import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Select from 'react-select';
import ReactGA from 'react-ga';

import * as API from '../apis';
import { LEVELS } from '../constants/levels';
import { ACTION, CATEGORY, LABEL } from '../constants/ga';
import { slugify } from '../utils/slug';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Video from '../components/Video';
import VideoPlaceholder from '../components/Placeholder/Video';
import MutedText from '../components/MutedText';
import Spinner from '../components/Spinner';
import { countViews } from '../utils/misc';

const Title = styled.h1`
  margin: 0 1rem 0 0;
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: capitalize;
  color: #10abe2;
`;

const VideoList = styled.div`
  display: grid;
  grid-gap: 1.875rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  margin-bottom: 1.875rem;
`;

const LevelSelect = styled(Select)`
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 2rem 0;
`;

const LoadMoreButton = styled.div`
  color: #10abe2;
  cursor: pointer;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoVideo = styled(MutedText)`
  grid-column: 1 / -1;
`;

function SubjectPage({ data }) {
  const { trangSubject: subject } = data;
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState({});
  const [selectedLevel, setSelectedLevel] = useState();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const fetchVideos = useCallback(
    async (limit = 20) => {
      try {
        setLoading(true);
        const response = await API.getVideosBySubject({
          subject: subject.code,
          level: selectedLevel && selectedLevel.value,
          page,
          limit,
        });
        setLoading(false);
        setVideos([...videos, ...response.data]);
        setHasMore(response.hasNextPages);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    },
    [subject, selectedLevel, page]
  );

  const handleLevelChange = selectedOption => {
    setVideos([]);
    setPage(1);
    setHasMore(false);
    setSelectedLevel(selectedOption);

    ReactGA.event({
      category: CATEGORY.VIDEO,
      action: ACTION.FILTER_BY_LEVEL,
      label: LABEL.SUBJECT_PAGE,
      value: selectedOption && selectedOption.value,
    });
  };

  useEffect(() => {
    fetchVideos();
  }, [fetchVideos]);

  return (
    <Layout>
      <SEO title={subject.name} />

      <Header>
        <Title>{subject.name}</Title>
        <LevelSelect
          isSearchable
          isClearable
          placeholder="Chọn lớp học"
          value={selectedLevel}
          options={LEVELS.map(level => ({ value: level, label: `Lớp ${level}` }))}
          onChange={handleLevelChange}
        />
      </Header>

      <VideoList>
        {loading && page === 1 && (
          <>
            <VideoPlaceholder />
            <VideoPlaceholder />
            <VideoPlaceholder />
            <VideoPlaceholder />
          </>
        )}
        {!loading && !(videos && videos.length) && (
          <NoVideo>
            Hiện tại Trạng đang xây dựng và hoàn thiện video của mục này. Xin quý khách vui lòng
            quay lại trong thời gian tới.
          </NoVideo>
        )}
        {videos &&
          videos.map(video => (
            <Video
              key={video.code}
              title={video.name}
              subtitle={video.subject && video.subject.name}
              thumbnail={video.thumbnail}
              link={`videos/${slugify(video.name)}`}
              duration={video.duration}
              viewCount={countViews(video.views, video.demoViews)}
              publishDate={video.publishDate}
              type={video.contentType}
              gaLabel={LABEL.SUBJECT_PAGE}
            />
          ))}
      </VideoList>

      <Footer>
        {hasMore && !loading && (
          <LoadMoreButton onClick={() => setPage(page + 1)}>Xem thêm</LoadMoreButton>
        )}

        {loading && hasMore && <Spinner />}
      </Footer>
    </Layout>
  );
}

SubjectPage.propTypes = {
  data: PropTypes.shape({
    trangSubject: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default SubjectPage;

export const pageQuery = graphql`
  query($code: String!) {
    trangSubject(code: { eq: $code }) {
      code
      name
    }
  }
`;
